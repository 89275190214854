import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateSupportTicketComponent = () => {
    let {t} = useTranslation();

    return (
        <div class="main-page__content-wrap">
            <h2 class="section__title">
                {t('support')}
            </h2>
            <div class="main-page__content">
                <section class="section-container support-create">
                    <div class="support-header">
                        <Link aria-current="page" class="support-header__back active" to="/support">
                            <svg width="5" height="8" fill="none">
                                <path d="M4 7 1 4l3-3" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            {t('back')}
                        </Link>
                        <div class="support-header__title">
                            {t('newTicket')}
                        </div>
                    </div>
                    <div class="support-create__form">
                        <div class="input__wrap">
                            <label class="input__label">
                                {t('title')}
                            </label>
                            <div class="input__input-wrap">
                                <input type="text" class="input" required="" />
                            </div>
                        </div>
                        <div class="input__label">
                            {t('messageText')}
                        </div>
                        <div class="support-field">
                            <textarea class="support-field__textarea" placeholder="Message" />
                            <ul class="support-upload__list" />
                            <div class="support-upload">
                                <div class="support-upload__upload" role="presentation" tabindex="0">
                                    <input accept="image/png,image/jpeg" multiple="" type="file" tabindex="-1" className="display-none" />
                                    <svg class="support-upload__icon support-upload__icon--desktop" width="20" height="20" fill="none">
                                        <path d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333c0 .9205.74619 1.6667 1.66667 1.6667H15.8333c.9205 0 1.6667-.7462 1.6667-1.6667V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z" stroke="#02AF6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.0835 8.33337c.69035 0 1.25-.55964 1.25-1.25 0-.69035-.55965-1.25-1.25-1.25-.69036 0-1.25.55965-1.25 1.25 0 .69036.55964 1.25 1.25 1.25ZM17.4998 12.5l-4.1666-4.16663L4.1665 17.5" stroke="#02AF6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <svg class="support-upload__icon support-upload__icon--mobile" width="16" height="14" fill="none">
                                        <path d="M9.88648 1.58688c1.17152-1.171586 3.07102-1.171586 4.24262 0 1.1325 1.1325 1.1703 2.94522.1133 4.12311l-.1133.11954L7.5 12.4586c-1.6109 1.6109-4.22271 1.6109-5.83362 0C.10021 10.8925.0567054 8.38024 1.53586 6.76152l.13052-.13652 5.56845-5.56846c.29289-.292896.76777-.292896 1.06066 0 .27036.27036.29116.69578.06239.99l-.06239.07066-5.56846 5.56846c-1.02512 1.02512-1.02512 2.68714.00001 3.71234.98851.9885 2.56928 1.0238 3.60009.1059l.11221-.1059 6.62916-6.62912c.5857-.58581.5858-1.53557 0-2.12134-.555-.55497-1.4366-.58417-2.0259-.08763l-.0954.08763-6.62916 6.62908c-.14645.14646-.14645.38393-.00003.53037.13019.13018.33226.14465.47842.0434l.05194-.0434L10.4168 4.2385c.2929-.29289.7678-.29289 1.0607 0 .2703.27036.2911.69579.0624.99l-.0624.07066-5.56847 5.56854c-.73225.7322-1.91943.7322-2.6517-.0001-.70034-.7004-.73079-1.81699-.09133-2.55361l.09136-.09801 6.62912-6.6291Z" fill="#33AA7E" />
                                    </svg>
                                    <span class="support-upload__upload-text support-upload__upload-text--desktop">
                                    {t('clickOrDrag')}
                                    </span>
                                    <span class="support-upload__upload-text support-upload__upload-text--mobile">
                                        {t('clickOrDrag')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="support-details__submit-wrap">
                            <button class="btn btn-loading support-details__submit" disabled="">
                                {t('send')}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default CreateSupportTicketComponent;