import './App.css';
import MainComponent from './components/MainComponent';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginComponent from './components/LoginComponent';
import i18next from "i18next";
import { initReactI18next} from "react-i18next";
import AuthGuard from './middleware/AuthGuard';

i18next.use(initReactI18next).init({
  resources: {
    ru: {
      translation: {
        multi: 'Мультивалютность',
        multiText: 'Получите доступ к более чем к 20 криптовалютам, к стейблкоинам и фиатным платежным системам',
        loginBuy: 'Покупка / продажа',
        loginBuyText: 'Покупайте и продавайте криптовалюты с помощью популярных платежных решений',
        loginConvert: 'Конвертация',
        loginConvertText: 'Проводите безопасные обменные операции в рамках с криптовалютами во всех направлениях',
        loginDebet: 'Дебетовая Карта',
        loginDebetText: 'Совершайте любые виды оплаты с использованием фиатной дебетовой карты от S-Wallet',
        loading: 'Загрузка',
        signIn: 'Войти',
        newUser: 'Новый пользователь?',
        createAccount: 'Создать аккаунт',
        password: 'Пароль',
        rememberMe: 'Запомнить меня',
        forgotPassword: 'Забыли пароль?',
        haveAnAccount: 'Уже есть аккаунт?',
        fullName: 'ФИО',
        iAgree: 'Я принимаю',
        terms: 'Условия и положения',
        signUp: 'Регистрация',
        forgotPasswordTitle: 'Восстановление пароля',
        forgotPasswordText: 'На Ваш контактный Email будет выслано письмо со ссылкой для восстановления пароля',
        iRememberedPassword: 'Я вспомнил свой пароль',
        send: 'Отправить',
        modalMainText1: 'Прежде чем отправить указанную сумму по адресу заявки, далее в имени компания',
        modalMainText2: '- имеющей лицензии для AML и KYC проверок просим вас пройти быструю проверку на чистоту ваших "электронных единиц".',
        modalMainText3: 'Для прохождения проверки нажмите кнопку ниже и следуйте дальнейшим инструкциям.',
        dash: 'Дашборд',
        operations: 'Операции',
        exchange: 'Обменять',
        stack: 'Стейкинг',
        info: 'Информация',
        transactions: 'Транзакции',
        news: 'Новости',
        help: 'Помощь',
        support: 'Поддержка',
        warnignTitle: 'Вниманиие!',
        checkWallet: 'Пройти проверку',
        currency: 'Валюта',
        networkName: 'Сеть перевода',
        time: 'время',
        sender: 'Адрес отправителя',
        senderPlaceholder: 'Введите адрес кошелька',
        sumToSend: 'Сумма к отправлению',
        sumToWithdraw: 'Сумма к списанию',
        limit: 'Суточный лимит',
        addressat: 'Адрес получателя',
        sumToGet: 'Сумма получения',
        comission: 'Комиссия',
        enterTheCode: 'Введите код',
        min: 'мин',
        use: 'Применить',
        menu: 'Меню',
        languange: 'Язык',
        account: 'Профиль',
        exit: 'Выход',
        clickOrDrag: 'Нажмите или перетащите файл сюда',
        messageText: 'Текст сообщения',
        title: 'Заголовок',
        newTicket: 'Новый тикет',
        back: 'Назад',
        opened: 'Открытые',
        closed: 'Закрытые',
        addTicket: 'Добавить тикет',
        recordsNotFound: 'Записей не обнаружено',
        lang: 'Русский',
        langRu: 'Русский',
        langEn: 'English'
      },
    },
    en: {
      translation: {
        multi: 'Multicurrency',
        multiText: 'Get access to over 20 cryptocurrencies, stablecoins and fiat payment systems',
        loginBuy: 'Buying / Selling',
        loginBuyText: 'Buy and sell cryptocurrencies with popular payment solutions',
        loginConvert: 'Conversion',
        loginConvertText: 'Conduct secure exchange transactions with cryptocurrencies in all directions',
        loginDebet: 'Debit card',
        loginDebetText: 'Make any type of payment using a fiat debit card from S-Wallet',
        loading: 'Loading',
        signIn: 'Sign in',
        newUser: 'New user?',
        createAccount: 'Create an account',
        password: 'Password',
        rememberMe: 'Remember me',
        forgotPassword: 'Forgot password?',
        haveAnAccount: 'Already have an account?',
        fullName: 'Full name',
        iAgree: 'I accept',
        terms: 'Terms and Conditions',
        signUp: 'Registration',
        forgotPasswordTitle: 'Password recovery',
        forgotPasswordText: 'A letter with a link to reset your password will be sent to your contact Email',
        iRememberedPassword: 'I remembered my password',
        send: 'Send',
        modalMainText1: 'Before sending the said amount to the application address, further in the name of',
        modalMainText2: '- a company licensed for AML and KYC verifications we request you to undergo a quick verification for the purity of your "electronic units".',
        modalMainText3: 'Click the button below and follow the further instructions to get verified.',
        dash: 'Dashboard',
        operations: 'Transactions',
        exchange: 'Exchange',
        stack: 'Stacking',
        info: 'Information',
        transactions: 'Transactions',
        news: 'News',
        help: 'Help',
        support: 'Support',
        warnignTitle: 'Attention!',
        checkWallet: 'Check wallet',
        currency: 'Currency',
        networkName: 'Transaction network',
        time: 'time',
        sender: "Sender's address",
        senderPlaceholder: 'Enter wallet address',
        sumToSend: 'Amount to be sent',
        sumToWithdraw: 'Amount to be withdraw',
        limit: 'Daily limit',
        addressat: 'Recipient address',
        sumToGet: 'Amount to be received',
        comission: 'Comission',
        enterTheCode: 'Enter the promo code',
        min: 'min',
        use: 'Apply',
        menu: 'Menu',
        languange: 'Languange',
        account: 'Account',
        exit: 'Exit',
        clickOrDrag: 'Click or drag file here',
        messageText: 'Message text',
        title: 'Title',
        newTicket: 'New ticket',
        back: 'Back',
        opened: 'Opened',
        closed: 'Closed',
        addTicket: 'Add ticket',
        recordsNotFound: 'No records found',
        lang: 'English',
        langRu: 'Русский',
        langEn: 'English'
      }
    },
  },
  lng: "ru",
  interpolation: {
    escapeValue: false
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<AuthGuard><MainComponent /></AuthGuard>} />
        <Route path='/login/*' element={ <LoginComponent /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
