import arrowDown from '../assets/arrow-down.svg';
import bitcoin from '../assets/bitcoin.svg';
import etherum from '../assets/etherum.svg';
import trx from '../assets/trx.svg';
import usdt from '../assets/usdt.svg';
import check from '../assets/check.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ModalComponent from './ModalComponent';
import { useTranslation } from 'react-i18next';

const ChangeDataComponent = () => {

    let [activeCoinInput, setActiveCoinInput] = useState();
    let [activeCoinOutput, setActiveCoinOutput] = useState();
    let [availableInputCoins, setInputCoins] = useState();
    let [availableOutputCoins, setOutputCoins] = useState();
    let [isActiveInputList, setActiveInput] = useState(false);
    let [isActiveOutputList, setActiveOutput] = useState(false);
    let [sumInput, setSumInput] = useState('');
    let [sumToGet, setSumToGet] = useState(0);
    let [comission, setComission] = useState(20);
    let [priceCourse, setPriceCourse] = useState(1);
    let [isLoading, setIsLoading] = useState(false);
    let [promoCode, setPromoCode] = useState('');
    let [limit, setLimit] = useState(true);
    let [modalOpen, setModalOpen] = useState(false);
    let [addresIn, setAddressIn] = useState('');
    let [addressOut, setAddressOut] = useState('');
    
    let { t } = useTranslation();

    const coins = [
        {
            name: 'Bitcoin',
            icon: bitcoin,
            code: 'BTC',
            chain: {
                name: 'BTC20',
                time: `~3 ${t('min')}`
            }
        },
        {
            name: 'Etherum',
            icon: etherum,
            code: 'ETH',
            chain: {
                name: 'ETC20',
                time: `~5 ${t('min')}`
            }
        },
        {
            name: 'Tron',
            icon: trx,
            code: 'TRX',
            chain: {
                name: 'TRON',
                time: `~1 ${t('min')}`
            }
        },
        {
            name: 'Tether',
            icon: usdt,
            code: 'USDT',
            chain: {
                name: 'TRON',
                time: `~1 ${t('min')}`
            }
        },
    ];

    const promoCodes = [
        {
            code: 'JNER87QNJ'
        },
        {
            code: 'UIERHJNSD'
        },
        {
            code: 'MLSMDL367'
        },
        {
            code: 'JSNDMKS90'
        },
        {
            code: 'QSDKLZX67'
        },
        {
            code: 'LOPWESD34'
        }
    ];

    useEffect(() => {
        setActiveCoinInput(coins[0]);
        setActiveCoinOutput(coins[0]);
    }, []);

    useEffect(() => {
        const someFunction = async () => {
            if (!activeCoinInput) {
                return;
            }
    
            setIsLoading(true);
            const text = await axios.get('https://api.change-s.com/todo', {
                params: {
                    symbol1: activeCoinInput.code + 'USDT',
                    symbol2: activeCoinOutput.code + 'USDT'
                }
            });

            setPriceCourse(text.data.price);
    
            const newCoins = coins.filter(i => i.name !== activeCoinInput.name);
    
            setInputCoins(newCoins);
            setSumInput('');
            setSumToGet('');
            setIsLoading(false);
        }
        someFunction();
    }, [activeCoinInput]);

    useEffect(() => {

        const someFunction = async () => {
            if (!activeCoinOutput) {
                return;
            }
    
            setIsLoading(true);
            const text = await axios.get('https://api.change-s.com/todo', {
                params: {
                    symbol1: activeCoinInput.code + 'USDT',
                    symbol2: activeCoinOutput.code + 'USDT'
                }
            });

            setPriceCourse(text.data.price);
    
            const newCoins = coins.filter(i => i.name !== activeCoinOutput.name);
    
            setOutputCoins(newCoins);
            setSumInput('');
            setSumToGet('');
            setIsLoading(false);
        }
        someFunction();
    }, [activeCoinOutput]);

    let InputCoinsList = () => { return availableInputCoins.map((item, index) => {
            return (
                <div onClick={() => handleInputCoinCahnge(item)} className="coin" key={index}>
                    <div className="coin-list-name">
                        <img src={item.icon} alt="coin" className="coin-list-name-icon" />
                        <span className="coin-list-text">{item.name}</span>
                    </div>
                    <div className="coin-list-code">
                        {item.code}
                    </div>
                </div>
            )
        });
    }

    let OutputCoinsList = () => { return availableOutputCoins.map((item, index) => {
        return (
            <div onClick={() => handleOutputCoinCahnge(item)} className="coin" key={index}>
                <div className="coin-list-name">
                    <img src={item.icon} alt="coin" className="coin-list-name-icon" />
                    <span className="coin-list-text">{item.name}</span>
                </div>
                <div className="coin-list-code">
                    {item.code}
                </div>
            </div>
        )
    });
}

    const openInputList = () => {
        setActiveInput(!isActiveInputList);
    }

    const openOutputList = () => {
        setActiveOutput(!isActiveOutputList);
    }

    const handleInputCoinCahnge = (coin) => {
        setActiveCoinInput(coin);
        setActiveInput(false);
    }

    const handleOutputCoinCahnge = (coin) => {
        setActiveCoinOutput(coin);
        setActiveOutput(false);
    }

    const handleSumInputChange = (event) => {
        if (sumInput === 0) {
            setSumInput();    
        }

        setSumInput(event.target.value);
        setSumToGet((event.target.value*priceCourse) - (((event.target.value*priceCourse)/100)*comission));
    }

    const handlePromoCodeChange = (event) => {
        setPromoCode(event.target.value);
    }

    const addressInChange = (event) => {
        setAddressIn(event.target.value);
    }

    const addressOutChange = (event) => {
        setAddressOut(event.target.value);
    }

    const handlePromoInit = () => {
        setIsLoading(true);
        const accessCode = promoCodes.find(item => item.code === promoCode);
        if (accessCode) {
            setComission(3);
            setLimit(false);
            setSumToGet((sumInput*priceCourse) - (((sumInput*priceCourse)/100)*3));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    const openModal = () => {
        setModalOpen(true);
      };
    
    const closeModal = () => {
        setModalOpen(false);
    };
    

    return (
        <div className="change-data">
      {modalOpen && 
        <ModalComponent 
            isOpen={modalOpen}
            onClose={closeModal}
            title={t('warningTitle')}
            buttonText={t('checkWallet')}
        />
    }
            <div className="change-data-wrapper">
                <h2 className="change-data-title">
                    {t('send')}
                </h2>
                { isLoading ? (
        <div className="loader">
          {/* You can replace this with your preferred loader */}
          <div className="spinner"></div>
          <p>{t('loading')}...</p>
        </div>
            ) : <>
                { (activeCoinInput && activeCoinOutput) ? <div className="change-data-section">
                    <div className="input-section">
                        <div className="input-coin-section">
                            <span className="input-header">
                                {t('currency')}
                            </span>
                            <div onClick={openInputList} className="input-coin">
                                <div className="coin-name">
                                    <img src={activeCoinInput.icon} alt="coin" className="coin-logo" />
                                    <span className="coin-name-text">{activeCoinInput.name}</span>
                                </div>
                                <div className="coin-select">
                                    <span className="choosed-name">
                                        {activeCoinInput.code}
                                    </span>
                                    <div className="line" />
                                    <img src={arrowDown} alt="arrow-down" className="arrow-down" />
                                </div>
                            </div>
                            { availableInputCoins ?  
                            <div className={`coins-list ${isActiveInputList ? 'active' : ''}`}>
                                {InputCoinsList()}
                            </div> : '' }
                        </div>
                        <div className="input-chain">
                            <span className="input-header">
                                {t('networkName')}
                            </span>
                            <div className="chain-select">
                                <img src={check} alt="check" className="chain-img" />
                                <span className="chain-name">{activeCoinInput.chain.name}</span>
                                <span className="chain-time">{t('time')}: <strong>{activeCoinInput.chain.time}</strong></span>
                            </div>
                        </div>
                        <div className="input-address">
                            <span className="input-header">
                                {t('sender')}
                            </span>
                            <input value={addresIn} onChange={addressInChange} placeholder={t('senderPlaceholder')} type="text" className="address-input" />
                        </div>
                        <div className="input-address">
                            <span className="input-header">
                                {t('sumToSend')}
                            </span>
                            <input value={sumInput} onChange={handleSumInputChange} placeholder='0' type="text" className="address-input" />
                        </div>
                        <div className="input-info">
                            <span className="get">{t('sumToWithdraw')}: <strong>{sumInput}</strong></span>
                            <span className="limit">{t('limit')}: <strong>{limit ? '20 BTC / 200 BTC' : '200 BTC / 200 BTC'}</strong></span>
                        </div>
                    </div>
                    <div className="output-section">
                        <div className="input-coin-section">
                            <span className="input-header">
                                {t('currency')}
                            </span>
                            <div onClick={openOutputList} className="input-coin">
                                <div className="coin-name">
                                    <img src={activeCoinOutput.icon} alt="coin" className="coin-logo" />
                                    <span className="coin-name-text">{activeCoinOutput.name}</span>
                                </div>
                                <div className="coin-select">
                                    <span className="choosed-name">
                                        {activeCoinOutput.code}
                                    </span>
                                    <div className="line" />
                                    <img src={arrowDown} alt="arrow-down" className="arrow-down" />
                                </div>
                            </div>
                            { availableOutputCoins ?  
                            <div className={`coins-list ${isActiveOutputList ? 'active' : ''}`}>
                                {OutputCoinsList()}
                            </div> : '' }
                        </div>
                        <div className="input-chain">
                            <span className="input-header">
                                {t('networkName')}
                            </span>     
                            <div className="chain-select">
                                <img src={check} alt="check" className="chain-img" />
                                <span className="chain-name">{activeCoinOutput.chain.name}</span>
                                <span className="chain-time">{t('time')}: <strong>{activeCoinOutput.chain.time}</strong></span>
                            </div>
                        </div>
                        <div className="input-address">
                            <span className="input-header">
                                {t('addressat')}
                            </span>
                            <input value={addressOut} onChange={addressOutChange} placeholder={t('senderPlaceholder')} type="text" className="address-input" />
                        </div>
                        <div className="input-info">
                            <span className="get">{t('sumToGet')}: <strong>{sumToGet}</strong></span>
                            <span className="comission">{t('comission')}: <strong>{comission}%</strong></span>
                        </div>
                        <div className="promo-code">
                            <input disabled={!limit} value={promoCode} onChange={handlePromoCodeChange} placeholder={t('enterTheCode')} type="text" className="promo-input" />
                            <span disabled={!limit} onClick={handlePromoInit} className="promo-activate">{t('use')}</span>
                        </div>
                        <span onClick={openModal} className={`send-button ${(addresIn && addressOut && sumInput) ? 'active' : ''}`}>{t('send')}</span>
                    </div>
                </div> : '' }</> }
            </div>
        </div>
    );
};

export default ChangeDataComponent;