import { Link } from 'react-router-dom';
import noSearch from '../assets/no_search.svg';
import { useTranslation } from 'react-i18next';

const SupportComponent = () => {
    let { t } = useTranslation();

    return (
        <div className="main-page__content-wrap">
            <h2 className="section__title">
                {t('support')}
            </h2>
            <div className="main-page__content">
                <section className="section-container support-main">
                    <div className="support-main__tabs-wrap">
                        <ul className="support-tabs">
                            <li className="support-tabs__item support-tabs__item--active">
                                {t('opened')}
                            </li>
                            <li className="support-tabs__item">
                                {t('closed')}
                            </li>
                        </ul>
                        <Link className="btn support-main__add-ticket" to="/support/create">
                                {t('addTicket')}
                        </Link>
                    </div>
                    <div className="support-main__tickets-wrap">
                        <div className="support-main__empty-text">
                            <img width="200px" height="200px" src={noSearch} alt="no search" />
                            <span>{t('recordsNotFound')}</span>
                        </div>
                        <div className="support-main__mobile">
                            <Link className="btn support-main__add-ticket" to="/support/create">
                                {t('addTicket')}
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default SupportComponent;