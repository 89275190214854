import dash from '../assets/dash.svg';
import send from '../assets/send.svg';
import change from '../assets/change.svg';
import stack from '../assets/stack.svg';
import spad from '../assets/spad.svg';
import transaction from '../assets/transaction.svg';
import news from '../assets/news.svg';
import support from '../assets/news.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const ChangeMenu = () => {
    let {t} = useTranslation();

    return (
        <div className="change-menu">
            <div className="change-menu-wrapper">
                <div className="dash">
                    <img src={dash} alt="dash" className="dash-image" />
                    <span className="dash-text">{t('dash')}</span>
                </div>
                <div className="operations">
                    <span className="header">
                        {t('operations')}
                    </span>
                    <div className="items">
                        <NavLink exact activeClassName="active" to='/' className="item">
                            <img src={send} alt="get" className="item-image" />
                            <span className="item-text">{t('send')}</span>
                        </NavLink>
                        <div className="item">
                            <img src={change} alt="get" className="item-image" />
                            <span className="item-text">{t('exchange')}  <strong>PRO</strong></span>
                        </div>
                        <div className="item">
                            <img src={stack} alt="get" className="item-image" />
                            <span className="item-text">{t('stack')}  <strong>PRO</strong></span>
                        </div>
                        <div className="item">
                            <img src={spad} alt="get" className="item-image" />
                            <span className="item-text">S-PAD <strong>PRO</strong></span>
                        </div>
                        <div className="item">
                            <img src={spad} alt="get" className="item-image" />
                            <span className="item-text">DAO  <strong>PRO</strong></span>
                        </div>
                    </div>
                </div>
                <div className="info">
                    <span className="header">
                        {t('info')}
                    </span>
                    <div className="items">
                        <div className="item">
                            <img src={transaction} alt="get" className="item-image" />
                            <span className="item-text">{t('transactions')} <strong>PRO</strong></span>
                        </div>
                        <div className="item">
                            <img src={news} alt="get" className="item-image" />
                            <span className="item-text">{t('news')} <strong>PRO</strong></span>
                        </div>
                    </div>
                </div>
                <div className="help">
                    <span className="header">
                        {t('help')}
                    </span>
                    <div className="items">
                        <NavLink activeClassName="active" to='/support' className="item">
                            <img src={support} alt="get" className="item-image" />
                            <span className="item-text support">{t('support')}</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeMenu;