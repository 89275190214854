import { useState } from 'react';
import logo from '../assets/logo.svg';
import axios from 'axios';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginComponent = () => {
    let { t, i18n } = useTranslation();
    let navigate = useNavigate();

    let [password, setPassword] = useState('');
    let [email, setEmail] = useState('');
    let [passwordState, setPasswordState] = useState('password');
    let [isLoading, setIsLoading] = useState(false);
    let [name, setName] = useState('');
    let [multilangActive, setActiveLang] = useState(false);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleName = (event) => {
        setName(event.target.value);
    }

    const changePasswordState = () => {
        if (passwordState === 'password') {
            setPasswordState('text');
        } else {
            setPasswordState('password');
        }
    }

    const handleLogin = async () => {
        try {
            setIsLoading(true);
            const data = await axios.post('https://api.change-s.com/login', { email, password });

            if (data.status === 200) {
                localStorage.setItem('isLoggedIn', JSON.stringify({ name: email }));
                navigate('/');
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            return;
        }
    }

    const handleRegister = async () => {
        try {
            setIsLoading(true);
            const data = await axios.post('https://api.change-s.com/login/create', { email, password, name });
            if (data.status === 200) {
                navigate('/login')
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            return;
        }
    }

    const handleMultiLang = () => {
        setActiveLang(!multilangActive);
    }


    return (
        <div className="login-page">
            <section className="info">
                <div className="login-hero__wrap">
                    <div className="login-hero__img-wrap">
                        <Link to="/login">
                            <img src={logo} alt="logo" className="login-hero__logo" />
                        </Link>
                        <div class={`multilang ${multilangActive ? 'multilang--active' : ''}`}>
                            <div onClick={handleMultiLang} class="multilang__lang multilang__lang--head">
                                <svg width="18" height="18" color="" class="multilang__head-prefix-icon" fill="none">
                                    <path d="M1 14c2.333-1.5 7-5.8 7-11M0 3h6m5 0H6m0 0V2 1M3 5c0 2 3 6 5 7" stroke="#fff" stroke-width="2" />
                                    <path fill-rule="evenodd" d="M13.442 6.664L12.5 4.027l-.942 2.637-2.497 6.993L7.807 17h2.136l.75-2h3.614l.75 2h2.136l-1.254-3.344-2.497-6.993zM13.581 13h-2.162L12.5 9.973 13.581 13z" fill="#fff" />
                                </svg>
                                {t('lang')}
                                <svg width="15" height="15" color="#fff" class="multilang__icon" viewBox="0 0 15 15" fill="none">
                                    <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <ul class="multilang__langs-list">
                                <li onClick={() => { i18n.changeLanguage('ru'); setActiveLang(false);}} class="multilang__lang multilang__lang--active multilang__lang--list">
                                    <span class="multilang__lang-name">
                                        {t('langRu')}
                                    </span>
                                </li>
                                <li onClick={() => { i18n.changeLanguage('en'); setActiveLang(false);}} class="multilang__lang multilang__lang--list">
                                    <span class="multilang__lang-name">
                                        {t('langEn')}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul className="login-hero__list">
                        <li className="login-hero__item">
                            <div className="login-hero__item-img-box">
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                                    <path d="M2 7L7 12L14 2" stroke="#02AF6F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="login-hero__item-info">
                                <div className="login-hero__item-name">
                                    {t('multi')}
                                </div>
                                <div className="login-hero__item-text">
                                    {t('multiText')}
                                </div>
                            </div>
                        </li>
                        <li className="login-hero__item">
                            <div className="login-hero__item-img-box">
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                                    <path d="M2 7L7 12L14 2" stroke="#02AF6F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="login-hero__item-info">
                                <div className="login-hero__item-name">
                                    {t('loginBuy')}
                                </div>
                                <div className="login-hero__item-text">
                                    {t('loginBuyText')}
                                </div>
                            </div>
                        </li>
                        <li className="login-hero__item">
                            <div className="login-hero__item-img-box">
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                                    <path d="M2 7L7 12L14 2" stroke="#02AF6F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="login-hero__item-info">
                                <div className="login-hero__item-name">
                                    {t('loginConvert')}
                                </div>
                                <div className="login-hero__item-text">
                                    {t('loginConvertText')}
                                </div>
                            </div>
                        </li>
                        <li className="login-hero__item">
                            <div className="login-hero__item-img-box">
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                                    <path d="M2 7L7 12L14 2" stroke="#02AF6F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="login-hero__item-info">
                                <div className="login-hero__item-name">
                                    {t('loginDebet')}
                                </div>
                                <div className="login-hero__item-text">
                                    {t('loginDebetText')}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="login-form">
                {isLoading ? (
                    <div className="loader">
                        {/* You can replace this with your preferred loader */}
                        <div className="spinner"></div>
                        <p>{t('loading')}...</p>
                    </div>
                ) : <Routes>
                    <Route path='/' element={
                        <div className="login-page__inner">
                            <h2 className="login-page__title title">
                                {t('signIn')}
                            </h2>
                            <div className="login-page__subtitle">
                                <span>
                                    {t('newUser')}
                                </span>
                                <Link className="btn-link" to="/login/register">
                                    {t('createAccount')}
                                </Link>
                            </div>
                            <div className="login-page__form">
                                <div className="input__wrap">
                                    <label className="input__label">
                                        Email
                                    </label>
                                    <div className="input__input-wrap">
                                        <input name="email" onChange={handleEmail} className="set-scroll input" autocomplete="off" value={email} />
                                    </div>
                                </div>
                                <div className="input__wrap">
                                    <label className="input__label">
                                        {t('password')}
                                    </label>
                                    <div className="input__input-wrap">
                                        <input name="password" onChange={handlePassword} className="set-scroll input input--password" autocomplete="off" type={passwordState} value={password} />
                                        <button onClick={() => changePasswordState()} type="button" className="input__show-password">
                                            <svg width="18" height="18" color="" className="" fill="none">
                                                <path d="M7.47273 3.35633C7.97333 3.23915 8.48586 3.18057 9 3.18178c5.0909 0 8 5.81818 8 5.81818-.4415.8259-.968 1.60344-1.5709 2.32004m-4.8873-.7782c-.1997.2143-.4406.3863-.70823.5055-.26764.1193-.55655.1834-.8495.1886-.29296.0051-.58395-.0488-.85563-.1585-.27167-.1097-.51846-.2731-.72564-.4802-.20718-.2072-.37051-.454-.48025-.72568-.10973-.27168-.16362-.56267-.15845-.85562.00517-.29296.06929-.58187.18854-.8495.11925-.26764.29118-.50851.50554-.70826M13.32 13.32c-1.2432.9476-2.757 1.4726-4.32 1.4981-5.09091 0-8-5.81814-8-5.81814.90465-1.68589 2.15937-3.15883 3.68-4.32L13.32 13.32zM1 1l16 16" stroke="#8692A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="login__row">
                                    <label className="input-checkbox__label">
                                        <input className="input-checkbox" autocomplete="off" type="checkbox" />
                                        <div className="input-checkbox__checked" />
                                        {t('rememberMe')}
                                    </label>
                                    <Link className="btn-link" to="/login/password-recovery">
                                        {t('forgotPassword')}
                                    </Link>
                                </div>
                                <div className="login__submit-wrap">
                                    <span onClick={handleLogin} className={`btn btn-loading login__submit ${(email && password) ? '' : 'disabled'}`}>
                                       {t('signIn')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    } />
                    <Route path='/register' element={
                    <section className="login-page__content sign-up">
                        <div className="login-page__inner">
                            <h2 className="login-page__title title">
                                {t('createAccount')}
                            </h2>
                            <div className="login-page__subtitle">
                                <span>
                                    {t('haveAnAccount')}
                                </span>
                                <Link className="btn-link" to="/login">
                                    {t('signIn')}
                                </Link>
                            </div>
                            <div className="login-page__form">
                                <div className="input__wrap">
                                    <label className="input__label">
                                        {t('fullName')}
                                    </label>
                                    <div className="input__input-wrap">
                                        <input onChange={handleName} name="first_name" className="set-scroll input" autocomplete="off" value={name} />
                                    </div>
                                </div>
                            <div className="input__wrap">
                                <label className="input__label">
                                    Email
                                </label>
                                <div className="input__input-wrap">
                                    <input onChange={handleEmail} name="email" className="set-scroll input" autocomplete="off" value={email} />
                                </div>
                            </div>
                            <div className="input__wrap">
                                <label className="input__label">
                                    {t('password')}
                                </label>
                                <div className="input__input-wrap">
                                    <input onChange={handlePassword} name="password" className="set-scroll input input--password" autocomplete="off" type={passwordState} value={password} />
                                    <button onClick={() => changePasswordState()} type="button" className="input__show-password">
                                        <svg width="18" height="18" color="" className="" fill="none">
                                            <path d="M7.47273 3.35633C7.97333 3.23915 8.48586 3.18057 9 3.18178c5.0909 0 8 5.81818 8 5.81818-.4415.8259-.968 1.60344-1.5709 2.32004m-4.8873-.7782c-.1997.2143-.4406.3863-.70823.5055-.26764.1193-.55655.1834-.8495.1886-.29296.0051-.58395-.0488-.85563-.1585-.27167-.1097-.51846-.2731-.72564-.4802-.20718-.2072-.37051-.454-.48025-.72568-.10973-.27168-.16362-.56267-.15845-.85562.00517-.29296.06929-.58187.18854-.8495.11925-.26764.29118-.50851.50554-.70826M13.32 13.32c-1.2432.9476-2.757 1.4726-4.32 1.4981-5.09091 0-8-5.81814-8-5.81814.90465-1.68589 2.15937-3.15883 3.68-4.32L13.32 13.32zM1 1l16 16" stroke="#8692A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <label className="input-checkbox__label">
                                <input className="input-checkbox" autocomplete="off" type="checkbox" />
                                <div className="input-checkbox__checked"></div>
                                {t('iAgree')}&nbsp;
                                <a href="https://s-wallet.ai/media/docs/terms_of_use.pdf" target="_blank" rel="noopener noreferrer" className="text--green">
                                    {t('terms')}
                                </a>
                            </label>
                            <span onClick={handleRegister} type="submit" className={`btn btn-loading sign-up__submit ${(email && password && name) ? '' : 'disabled'}`}>
                                {t('signUp')}
                            </span>
                        </div>
                    </div>
                </section>} />
                <Route path='/password-recovery' element={
                    <section className="login-page__content password-recovery">
                        <div className="login-page__inner">
                            <h2 className="login-page__title title">
                                {t('forgotPasswordTitle')}
                            </h2>
                        <div className="login-page__subtitle password-recovery__subtitle">
                            <span>
                                {t('forgotPasswordText')}
                            </span>
                            <Link className="btn-link" to="/login">
                                {t('iRememberedPassword')}
                            </Link>
                        </div>
                        <div className="login-page__form">
                            <div className="input__wrap">
                                <label className="input__label">
                                    Email
                                </label>
                                <div className="input__input-wrap">
                                    <input name="email" className="set-scroll input" autocomplete="off" value="" />
                                </div>
                            </div>
                            <span type="submit" className="btn btn-loading password-recovery__submit">
                                {t('send')}
                            </span>
                        </div>
                    </div>
                </section>
                } />
                </Routes>
                }
            </section>
        </div>
    )
}

export default LoginComponent;